import { useUser } from "../../contexts/UserContext";

interface IntegrationInterface {
    type: string;
}

export const IntegrationSteps = (props: IntegrationInterface) => {
    const { user } = useUser();

    const embedStr = `<iframe id="${props.type == "AgeVerification" ? "age" : "fr"}-iframe" src="https://${props.type == "AgeVerification" ? "demo" : "fr"}.voltox.ai/${props.type == "AgeVerification" ? "landing.html" : ""}?data=${encodeURIComponent(user?.clientId ?? "")}" style="width: 100%; height: 100vh;" allow="camera" allowFullScreen></iframe>`;
    const listenStr = `<script>
    function handleMessage(event) {
        const iframe = document.getElementById('${props.type == "AgeVerification" ? "age" : "fr"}-iframe');

        if (iframe && event.source === iframe.contentWindow) {
            if (event.data && event.data.response) {
                // Handle the response from the iframe, your code goes from here!  
            }
        }
    }

// Add the event listener for the 'message' event
window.addEventListener('message', handleMessage);
</script>`;

    const copyEmbedToClipboard = () => {
        navigator.clipboard.writeText(embedStr).then(() => {
            alert("Iframe code copied to clipboard!");
        }).catch((err) => {
            console.error("Failed to copy text: ", err);
        });
    };

    const copyListenToClipboard = () => {
        navigator.clipboard.writeText(listenStr).then(() => {
            alert("Listen Message code copied to clipboard!");
        }).catch((err) => {
            console.error("Failed to copy text: ", err);
        });
    };

    return (
        <>
            <h2 style={{ fontSize: "30px", fontWeight: "500" }}>What are the next steps?</h2>
            <p>
                Integrate our solution seamlessly into your web application or platform by following the steps below.
                Our easy-to-implement iframe solution ensures compliance and security.
            </p>

            <p>Please note that this integration is designed specifically for <b>JavaScript</b>. Follow the steps carefully to ensure proper implementation within your environment.</p>

            <ol>
                <li className="mt-3">
                    <strong>Embed the IFrame:</strong> Copy the iframe snippet below and paste it into the relevant section of your web application or platform.
                    <br /><br />
                    <textarea
                        readOnly
                        value={embedStr}
                        className="iframe-snippet"
                    ></textarea>
                    <br />
                    <button
                        className="greenBg mb-4"
                        onClick={copyEmbedToClipboard}
                        style={{
                            marginTop: "10px",
                            padding: "10px 20px",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer"
                        }}
                    >
                        Copy to Clipboard
                    </button>
                </li>
                <li>Now that you have integrated the iframe, you can proceed with the next step which is listening for response from the iframe!</li>
                <br />
                <li>
                    <strong>Listening for Response:</strong>
                    <br /><br />
                    <textarea
                        readOnly
                        value={listenStr}
                        className="iframe-snippet"
                        style={{ minHeight: "340px" }}
                    >
                    </textarea>
                    <br />
                    <button
                        className="greenBg mb-4"
                        onClick={copyListenToClipboard}
                        style={{
                            marginTop: "10px",
                            padding: "10px 20px",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer"
                        }}
                    >
                        Copy to Clipboard
                    </button>
                </li>
                <li>
                    <strong>Test the Integration:</strong> Try testing the {props.type == "AgeVerification" ? "age verification" : "face recognition"} that you embedded and check out the response you get in the handleMessage function.
                </li>
                <li className="mt-3">
                    <strong>Security: </strong> Access to this iframe is restricted to the endpoint you provide to the admin. We support cross-domain, ensuring that your iframe cannot be stolen or impersonated by unauthorized parties.
                </li>
                <li className="mt-3">
                    <strong>Go Live:</strong> After testing, deploy the changes to your live environment and ensure everything is working smoothly for your users.
                </li>
                <li className="mt-3">
                    <strong>Monitor and Troubleshoot: </strong> Once live, monitor the integration closely for any potential issues. If any errors occur, check the browser console and ensure the iframe is receiving the expected data.
                </li>
                <li className="mt-3">
                    <strong>Compliance Check: </strong> Ensure that your implementation complies with any relevant legal requirements, such as data privacy laws, in your region.
                </li>
                <li className="mt-3">
                    <strong>Need help? </strong> Contact us at <a href="mailto:support@voltox.ch" className="email-clickable">support@voltox.ch</a>
                </li>
            </ol >
        </>
    );
};