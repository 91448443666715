import { Check, Drama, IdCard, LockOpen, X } from "lucide-react"
import { ReactNode } from "react"
import { cn } from "../../lib/utils"
import { Card, CardContent, CardHeader, CardTitle } from "./card"

interface MetricCardProps {
    title: string
    value: string[]
    icon?: ReactNode
    change?: string
    className?: string
    isSubActive: boolean | undefined
}

export function MetricCard({ title, value, icon, change, className, isSubActive }: MetricCardProps) {
    return (
        <Card className={cn("", className)}>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                    {title}
                </CardTitle>
                {icon}
            </CardHeader>
            <CardContent>
                <div className="text-l font-bold">
                    {value.length > 1 ?
                        <div className="row">
                            <span className="col-md-3 d-flex align-items-center" title="Total Successful Face Scans"><Check className="h-5 w-5 text-muted-foreground mr-1" /> {value[0]}</span>
                            <span title="Total Failed Face Scans" className="col-md-3 d-flex align-items-center"><X className="h-5 w-5 text-muted-foreground mr-1" /> {value[1]}</span>
                            <span title="Total Successful ID Verifications" className="col-md-3 d-flex align-items-center"><IdCard className="h-5 w-5 text-muted-foreground mr-1" /> {value[2]}</span>
                            {title == "Age Verification" ? <span className="col-md-3 d-flex align-items-center" title="Total Spoofs"><Drama className="h-5 w-5 text-muted-foreground mr-1" /> {value[3]}</span> : null}
                        </div>
                        : <span>{value}</span>
                    }
                </div>
                {change && (
                    <p className="text-xs text-muted-foreground">
                        {change} from last month
                    </p>
                )}
            </CardContent>
            {!isSubActive &&
                <>
                    <div className="d-flex flex-column align-items-center justify-content-center m-auto" style={{ position: "relative", width: "90%", textAlign: "center", top: "-30%", fontSize: "14px", color: "rgb(27, 82, 87)" }}>
                        <h3 className="text-center d-flex align-items-center justify-content-center" style={{ zIndex: 2 }}><LockOpen /></h3>
                        <span className="mt-3" style={{ zIndex: 9, fontWeight: 900, lineHeight: "1.5rem" }}>Subscribe To View <br />{title}</span>
                    </div>
                    <div id="blurDiv"></div>
                </>
            }
        </Card>
    )
} 