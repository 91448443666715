import axios from "axios";

interface AgeVerification {
    is_verified: boolean;
    predicted_age: number;
    documented_age: number;
}

export interface VerificationData {
    request_id: string;
    age: number;
    gender: string;
    race: string;
    emotion: string;
    is_face_visible: number;
    status: string;
    is_spoof: number;
    age_verification: AgeVerification | null;
    created_at?: string;
}

export interface FormattedVerificationData extends Omit<VerificationData, 'is_face_visible' | 'is_spoof' | 'age' | 'age_verification'> {
    is_face_visible: string;
    is_spoof: string;
    age: string;
    age_verification: string;
}

export const verificationService = {
    async getVerificationHistory(clientId: string, isForFace: boolean): Promise<any[]> {
        try {
            let response: any;

            if (isForFace) {
                response = await axios.get<any[]>(`${process.env.REACT_APP_AI_API_URL}/get-client-data?client_id=${clientId}&key=face_search`);
                return response.data;
            }
            else {
                response = await axios.get<VerificationData[]>(`${process.env.REACT_APP_AI_API_URL}/get-client-data`, {
                    params: { client_id: clientId }
                });

                return response.data;
            }
        } catch (error) {
            console.log('Error fetching verification history:', error);
            throw new Error('Failed to fetch verification history');
        }
    }
}; 