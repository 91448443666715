import { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { formatDateTime } from "../../lib/utils";
import { FormattedVerificationData, verificationService } from "../../services/verification.service";
import { ContactModal } from "../ContactModal";
import { DataTable } from "../DataTable";
import { Card, CardContent } from "../ui/card";
import { IntegrationSteps } from "./IntegrationSteps";

export const FaceRecognition = () => {
    const user = useUser().user;
    const [error, setError] = useState<string | null>(null);
    const [verificationData, setVerificationData] = useState<FormattedVerificationData[]>([]);
    const [answerFromIframe, setAnswerFromIframe] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const columns = [
        {
            header: "Request ID",
            accessorKey: "request_id",
        },
        {
            header: "Face Found",
            accessorKey: "is_face_found",
            className: "w-20",
        },
        {
            header: "Spoof Detected",
            accessorKey: "is_spoof",
            className: "w-24",
        },
        {
            header: "Name",
            accessorKey: "name",
        },
        {
            header: "Date",
            accessorKey: "created_at",
            className: "w-40",
        },
    ];
    let expiresOn = "";
    let subscription = null;
    let frShouldShow = false;

    if (user) {
        subscription = JSON.parse(user != null ? user.frSubscription : "");

        if (subscription.IsActive.toLowerCase() == "true" && new Date(subscription.Expires) > new Date()) {
            frShouldShow = true;
        }
        expiresOn = new Date(subscription.Expires).toLocaleString();
    }

    useEffect(() => {
        const fetchVerificationHistory = async () => {
            if (!user?.clientId) {
                setError("No client ID available");
                setIsLoading(false);
                return;
            }

            try {
                const data = await verificationService.getVerificationHistory(user.clientId, true);
                const formattedData = data.map(item => ({
                    ...item,
                    is_face_found: item?.face_search?.face_search?.is_face_found == true ? "YES" : "NO",
                    is_spoof: item?.face_search?.face_search?.is_spoof == 1 ? "YES" : "NO",
                    name: item?.face_search?.face_search?.matched_faces?.[0]?.metadata?.name,
                    created_at: formatDateTime(item?.created_at || ""),
                }));

                const sortedData = formattedData.sort((a, b) => {
                    const dateA = parseDate(a.created_at) as any;
                    const dateB = parseDate(b.created_at) as any;

                    return dateB - dateA; // Sort descending (latest first)
                });

                setVerificationData(sortedData);
                setError(null);
            } catch (err) {
                setError("Failed to fetch verification history");
                console.log("Error fetching verification history:", err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchVerificationHistory();

        //listen to iframe for messages
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [answerFromIframe]);

    const handleMessage = (event) => {
        const iframe = document.getElementById('fr-iframe') as HTMLIFrameElement;

        if (iframe && event.source === iframe.contentWindow) {
            if (event.data && event.data.response) {
                setAnswerFromIframe(event.data.response);
                // const dataToSend = {
                //     message: 'Hello from parent!',
                //     someValue: 42
                // };
                //iframe.contentWindow.postMessage(dataToSend, '*');
            }
        }
    };

    function parseDate(dateString: string) {
        const [day, month, yearTime] = dateString.split('/');
        const [year, time] = yearTime.split(' ');
        const [hour, minute] = time.split(':');

        return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    }

    return (
        <div className="space-y-4 fade-in">
            <div className="d-flex flex-column flex-sm-row gap-4 justify-between">
                <h2 className="text-3xl font-bold tracking-tight">
                    {subscription.Type == "Demo" ? "Facial Recognition Demo" : "Facial Recognition - " + subscription.Type}
                </h2>
                <div className="d-flex align-items-center">
                    {!frShouldShow &&
                        <h1 className="font-bold mr-2">Expired On:</h1>
                    }
                    {frShouldShow &&
                        <h1 className="font-bold mr-2">Expires On:</h1>
                    }
                    {expiresOn.split(",")[0]}
                </div>
            </div>
            <div className="rounded-xl bg-card text-card-foreground"><div className="p-0 border rounded-lg shadow-none"></div></div>
            {frShouldShow && subscription.Type == "Demo" &&
                <iframe id="fr-iframe" src={`https://fr.voltox.ai/?data=${encodeURIComponent(user?.clientId ?? "")}`} style={{ width: "100%", height: "100vh" }} allow="camera" allowFullScreen>
                </iframe>
                // <iframe id="fr-iframe" src={`https://127.0.0.1:5501/voltox-face-recognition/public/?data=${encodeURIComponent(user?.clientId ?? "")}`} style={{ width: "100%", height: "100vh" }} allow="camera" allowFullScreen>
                // </iframe>
            }
            {(frShouldShow && subscription.Type != "Demo") &&
                <IntegrationSteps type="FaceRecognition"/>
            }
            {!frShouldShow && (subscription.IsActive.toLowerCase() != "true" || new Date(subscription.Expires) < new Date()) &&
                <div className="text-center">
                    <h1 className="text-center mt-5 mb-4">
                        Seems like your subscription has expired or has been deactivated.
                        <br /><br />Please contact support below.
                    </h1>
                    <ContactModal type={"FR"} user={user} buttonText={"Request Access"} />
                </div>
            }
            <br />
            <br />
            <br />

            {frShouldShow &&
                <div className="flex items-center justify-between">
                    <h2 className="text-3xl font-bold tracking-tight">
                        Face Recognition History
                    </h2>
                </div>
            }

            {frShouldShow &&
                <Card>
                    <CardContent className="p-0 border rounded-lg shadow-none">
                    </CardContent>
                </Card>
            }

            {frShouldShow &&
                <Card>
                    <CardContent className="p-0 border rounded-lg shadow-none">
                        {error ? (
                            <div className="text-center text-red-500 p-4">{error}</div>
                        ) : verificationData.length === 0 && !isLoading ? (
                            <div className="text-center text-gray-500 p-4">No recognition history found</div>
                        ) : (
                            <DataTable
                                data={verificationData}
                                columns={columns}
                                isLoading={isLoading}
                            />
                        )}
                    </CardContent>
                </Card>
            }
        </div >
    );
}; 