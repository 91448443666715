import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useApiService } from "../services/api.service";
import { Button } from "./ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "./ui/dialog";
import { Label } from "./ui/label";

interface IFormInput {
    userId: number;
    feature: string;
    plan: string;
    duration: string;
    email: string;
}

export function ContactModal(props) {
    const { post } = useApiService();
    const [open, setOpen] = useState(false);
    const { register, handleSubmit, reset } = useForm<IFormInput>();
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({}); // To track validation errors
    const [loading, setLoading] = useState(false);
    const [plan, setPlan] = useState<string>();

    const onSubmit: SubmitHandler<IFormInput> = async data => {
        setLoading(true);
        const errors: { [key: string]: string } = {};

        if (!data.plan || data.plan.trim() === "") {
            errors.plan = "Plan is required";
        }
        if (!data.duration || data.duration.trim() === "") {
            errors.duration = "Duration is required";
        }
        if (!data.email || data.email.trim() === "") {
            errors.email = "Email is required";
        }

        if (Object.keys(errors).length > 0) {
            setLoading(false);
            setFieldErrors(errors);
            return; // Do not proceed if there are validation errors
        }

        setFieldErrors({}); // Clear errors if validation passes

        data.feature = (props.type == "FR" ? "FaceRecognition" : "AgeVerification");
        data.userId = parseInt(props.user.userId);
        const response = await post("/api/User/ContactAdminForSubscription", data) as any;

        if (!response?.flag) {
            toast.success(response.message);
            throw new Error(response.message);
        }

        setLoading(false);
        setOpen(false);
        toast.success("Request sent successfully, somebody from our team will get back to you shortly!");
        reset();
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button style={{ maxWidth: "350px", width: "100%" }} className="greenBg">{props.buttonText}</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Fill the form to request access</DialogTitle>
                    <DialogDescription className="mt-3">
                        Choose your the plan and duration of your {props.type == "FR" ? "Face Recognition" : "Age Verification"} subscription.
                    </DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit(onSubmit)} className="">
                    <div className="grid gap-4 py-3">
                        <div className="d-flex items-center justify-content-between gap-4">
                            <Label htmlFor="Plan" className={fieldErrors.plan ? "red text-left col-2 me-2" : "text-left col-2 me-2"}>
                                Plan
                            </Label>
                            <select
                                id="Plan"
                                className={fieldErrors.plan ? 'form-select is-invalid' : 'form-select'}
                                {...register("plan")}
                                onChange={(e) => setPlan(e.currentTarget.value)}
                            >
                                <option value="">Select a plan</option>
                                <option value="Demo">Demo</option>
                                <option value="Go">Go</option>
                                <option value="Boost">Boost</option>
                                <option value="Premium">Premium</option>
                            </select>
                        </div>
                        <div className="d-flex items-center justify-content-between gap-4">
                            <Label htmlFor="Duration" className={fieldErrors.duration ? "red text-left col-2 me-2" : "text-left col-2 me-2"}>
                                Duration
                            </Label>
                            <select
                                id="Duration"
                                className={fieldErrors.duration ? 'form-select is-invalid' : 'form-select'}
                                {...register("duration")}
                            >
                                <option value="">Select duration</option>
                                {plan == "Demo" ? (<option value="7">7 Days</option>) :
                                    (
                                        <>
                                            <option value="6">6 Months</option>
                                            <option value="12">12 Months</option>
                                        </>
                                    )
                                }
                            </select>
                        </div>
                        <div className="d-flex items-center justify-content-between gap-4">
                            <Label htmlFor="Email" className={fieldErrors.email ? "red text-left col-2 me-2" : "text-left col-2 me-2"}>
                                Email/Phone
                            </Label>
                            <input type="text" className={fieldErrors.email ? 'form-control is-invalid' : 'form-control'} placeholder="Where should we contact you?" {...register("email")} />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button type="submit" disabled={loading} className={!loading ? "greenBg w-100 mt-3" : "loading w-100 mt-3"}>Submit</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}
